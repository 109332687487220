<template>
  <ul
    class="distribution__links"
    :class="{'distribution__links_grid': grid}"
  >
    <template v-for="directory of activeDirectories">
      <li
        v-if="directoriesInfo[directory.directory_name]"
        :key="directory.directory_id"
        class="distribution__links-el"
      >
        <DistributionLink
          class="distribution__link"
          :link="directory.link_url"
          :name="directoriesInfo[directory.directory_name].name"
        >
          <component :is="directoriesInfo[directory.directory_name].icon" v-if="!directory.logo" />
          <img
            v-else
            :src="`/images/platforms/${directory.logo}.png`"
            :srcset="`/images/platforms/${directory.logo}@2x.png 2x`"
            :alt="directoriesInfo[directory.directory_name].name"
          ></img>
        </DistributionLink>
      </li>
    </template>
  </ul>
</template>

<script>
import DistributionLink from './link'
import { directoriesData, getActiveDirectories } from '@/lib/directories'

import TuneInIcon from '@/components/icons/dashboard/platforms/TuneIn.vue'
import DeezerIcon from '@/components/icons/dashboard/platforms/Deezer.vue'
import PandoraIcon from '@/components/icons/dashboard/platforms/Pandora.vue'
import SamsungIcon from '@/components/icons/dashboard/platforms/Samsung.vue'
import PodcastIndexIcon from '@/components/icons/dashboard/platforms/PodcastIndexIcon.vue'
import SpotifyIcon from '@/components/icons/dashboard/platforms/Spotify.vue'
import AmazonMusicIcon from '@/components/icons/dashboard/platforms/AmazonMusic.vue'
import IHeartRadioIcon from '@/components/icons/dashboard/platforms/IHeartRadio.vue'
import ApplePodcastsIcon from '@/components/icons/dashboard/platforms/ApplePodcasts.vue'

export default {
  components: {
    TuneInIcon,
    DeezerIcon,
    PandoraIcon,
    PodcastIndexIcon,
    SamsungIcon,
    SpotifyIcon,
    AmazonMusicIcon,
    IHeartRadioIcon,
    ApplePodcastsIcon,
    DistributionLink
  },

  props: {
    grid: {
      type: Boolean,
      default: false
    },
    podcast: {
      type: Object,
      default: null
    }
  },

  computed: {
    directoriesInfo () {
      return directoriesData
    },

    activeDirectories () {
      return getActiveDirectories(this.podcast)
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  &__links {
    width: 100%;
    display: flex;
    list-style: none;
    &_grid {
      width: 100%;
      display: grid;
      grid-gap: $gap * 1;
      grid-template-columns: 1fr 1fr 1fr;

      @include display-less(phablet) {
        grid-template-columns: 1fr 1fr;
      }

      @include display-less(phone) {
        grid-template-columns: 1fr;
      }
    }

    &-el {
      width: 100%;
      &:first-child {
        display: block;
      }

      @include display-less(desktop) {
        display: block;
      }
    }

    &_open {
      .distribution__links-el {
        display: block;
      }
    }
  }
}
</style>
