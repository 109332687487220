import { config } from '@/config'

export const directoriesData = {
  iTunes: {
    icon: 'ApplePodcastsIcon',
    name: 'Apple Podcasts',
    restriction: 'status'
  },
  Spotify: {
    icon: 'SpotifyIcon',
    name: 'Spotify',
    restriction: 'status'
  },
  'Amazon Music': {
    icon: 'AmazonMusicIcon',
    name: 'Amazon Music',
    restriction: 'status'
  },
  Samsung: {
    icon: 'SamsungIcon',
    name: 'Samsung',
    restriction: 'status'
  },
  Pandora: {
    icon: 'PandoraIcon',
    name: 'Pandora',
    restriction: 'status'
  },
  iHearthRadio: {
    icon: 'IHeartRadioIcon',
    name: 'iHeartRadio',
    restriction: 'status'
  },
  Deezer: {
    icon: 'DeezerIcon',
    name: 'Deezer',
    restriction: 'status'
  },
  'Tune In': {
    icon: 'TuneInIcon',
    name: 'Tune In',
    restriction: 'status'
  },
  PodcastIndex: {
    icon: 'PodcastIndexIcon',
    name: 'Podcast Index',
    restriction: 'status'
  },
  Castro: {
    icon: 'CastroIcon',
    name: 'Castro',
    dependsOn: 'iTunes',
    restriction: 'itunes_id',
    baseUrl: 'https://castro.fm/itunes/'
  },
  Castbox: {
    icon: 'CastboxIcon',
    name: 'Castbox',
    dependsOn: 'iTunes',
    restriction: 'itunes_id',
    baseUrl: 'https://castbox.fm/vic/'
  },
  Fountain: {
    icon: 'FountainIcon',
    name: 'Fountain',
    dependsOn: 'PodcastIndex',
    restriction: 'podcast_index_id',
    baseUrl: 'https://fountain.fm/show/'
  },
  Overcast: {
    icon: 'OvercastIcon',
    name: 'Overcast',
    dependsOn: 'iTunes',
    restriction: 'itunes_id',
    baseUrl: 'https://overcast.fm/itunes'
  },
  PocketCasts: {
    icon: 'PocketCastsIcon',
    name: 'Pocket Casts',
    dependsOn: 'iTunes',
    restriction: 'itunes_id',
    baseUrl: 'https://pca.st/itunes/'
  },
  PodcastAddict: {
    icon: 'PodcastAddictIcon',
    name: 'Podcast Addict',
    dependsOn: 'iTunes',
    restriction: 'rss_feed',
    baseUrl: 'https://podcastaddict.com/feed/'
  },
  PodcastGuru: {
    icon: 'PodcastGuruIcon',
    name: 'Podcast Guru',
    dependsOn: 'iTunes',
    restriction: 'itunes_id',
    baseUrl: 'https://app.podcastguru.io/podcast/'
  },
  TrueFans: {
    icon: 'TrueFansIcon',
    name: 'True Fans',
    dependsOn: 'PodcastIndex',
    restriction: 'show_guid',
    baseUrl: 'https://truefans.fm/'
  }
}

export const getActiveDirectories = (podcast) => {
  if (!podcast || !podcast.directories) {
    return []
  }

  const primaryDirectories = podcast.directories.filter((el) => {
    return el.link_url && el.directory_name !== 'ListenNotes'
  })

  const directories = []

  Object.keys(directoriesData).forEach((key) => {
    const directoryInfo = directoriesData[key]
    const primaryDirectoryInfo = getPrimaryDirectory(primaryDirectories, key)
    if (!directoryInfo.dependsOn && primaryDirectoryInfo) {
      // Primary Directory
      directories.push(primaryDirectoryInfo)
    } else {
      // Secondary Directory
      const primaryDirectoryExists = getPrimaryDirectory(primaryDirectories, directoryInfo.dependsOn)
      const directoryLink = getDirectoryLink(directoryInfo, podcast)
      if (primaryDirectoryExists && directoryLink) {
        directories.push({
          directory_id: key,
          directory_name: key,
          logo: key,
          link_url: directoryLink
        })
      }
    }
  })
  return directories
}

const getPrimaryDirectory = (primaryDirectories, key) => {
  if (!key) {
    return null
  }

  return primaryDirectories.find(el => el.directory_name === key)
}

const getDirectoryLink = (directoryInfo, podcast) => {
  const itunesId = getItunesId(podcast)
  if (directoryInfo.restriction === 'itunes_id' && itunesId) {
    return `${directoryInfo.baseUrl}${itunesId}`
  }

  const podcastIndexId = getPodcastIndexId(podcast)
  if (directoryInfo.restriction === 'podcast_index_id' && podcastIndexId) {
    return `${directoryInfo.baseUrl}${podcastIndexId}`
  }

  const podcastGuid = getPodcastGuid(podcast)
  if (directoryInfo.restriction === 'show_guid' && podcastGuid) {
    return `${directoryInfo.baseUrl}${podcastGuid}`
  }

  const rssFeed = getRssFeed(podcast)
  if (directoryInfo.restriction === 'rss_feed') {
    return `${directoryInfo.baseUrl}${rssFeed}`
  }

  return null
}

const getPodcastGuid = (podcast) => {
  return podcast.show_guid ?? null
}

const getItunesId = (podcast) => {
  const itunesDirectory = podcast.directories.find(el => el.directory_name === 'iTunes')
  const itunesLink = itunesDirectory?.link_url ?? null
  if (!itunesLink) {
    return null
  }
  const itunesId = itunesLink.split('/')[itunesLink.split('/').length - 1]
  return itunesId.split('?')[0].replace('id', '')
}

const getPodcastIndexId = (podcast) => {
  const podcastIndexDirectory = podcast.directories.find(el => el.directory_name === 'PodcastIndex')
  const podcastIndexLink = podcastIndexDirectory?.link_url ?? null
  if (!podcastIndexLink) {
    return null
  }
  return podcastIndexLink.split('/')[podcastIndexLink.split('/').length - 1]
}

const getRssFeed = (podcast) => {
  return `${config.mediaUrl}/${podcast.slug}/feed.xml`
}
