<template>
  <component :is="icon" :class="classes" />
</template>

<script>
import Close from './icons/Close'

export default {
  components: {
    Close
  },

  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'white'
    },
    size: {
      type: String,
      default: 'medium'
    }
  },

  computed: {
    icon () {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1)
    },

    classes () {
      return [
        'commonIcon',
        `commonIcon_${this.size ? this.size : 'medium'}`,
        `commonIcon_${this.color ? this.color : 'white'}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.commonIcon {
  vertical-align: middle;

  /deep/ path {
    fill: currentcolor;
  }

  &_medium {
    width: 24px;
    height: 24px;
  }

  &_white {
    color: #fff;
  }
}
</style>
